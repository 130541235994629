import React, {useState, useEffect} from 'react';
import useScrollBlock from '../../components/hooks/useScrollBlock';

import SEO from "../../components/en/seo/Seo";
import Header from "../../components/en/headers/Header";
import SliderHome from "../../components/en/headers/SliderHome";
import Description from "../../components/en/index/Description";
import MineServices from "../../components/en/index/MineService";
import Characteristics from "../../components/en/index/Characteristics";
import MineEvents from '../../components/en/index/MineEvents';
import Testimonial from '../../components/en/index/Testimonial';
import Footer from '../../components/en/layouts/Footer';
import MineServicesDetails from "../../components/en/index/MineServiceDetails";
import CallToAction from '../../components/en/layouts/CallToAction';
import NewsSimple from "../../components/en/index/NewsSimple";

const IndexPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const [blockScroll, allowScroll] = useScrollBlock();

    useEffect(() => {
        if(openModal){
            blockScroll();
        } else {
            allowScroll();
        }
    }, [openModal]);

    const schema =
        {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sitarjevec Litija Mine",
            "image": "https://i.ibb.co/LC8BbgQ/rudnik-sitarjevec.jpg",
            "@id": "",
            "url": "https://rudniksitarjevec.si/en",
            "telephone": "0038659940503",
            "priceRange": "10-30€",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Sitarjevška cesta 46",
                "addressLocality": "Litija",
                "postalCode": "1270",
                "addressCountry": "SI"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": 46.04948,
                "longitude": 14.83075
            },
            "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday"
                ],
                "opens": "09:00",
                "closes": "12:00"
            },{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday"
                ],
                "opens": "15:00",
                "closes": "18:00"
            }]
        };

    return (
        <>
            <SEO title="Experience the richness of minerals and the stories of the miners" schemaMarkup={schema}/>
            <Header/>
            <SliderHome handleSetModalOpen={setOpenModal}/>
            <Description/>
            <MineServices/>
            <MineServicesDetails/>
            <Characteristics/>
            <MineEvents/>
            <Testimonial/>
            {/*<NewsSimple/>*/}
            <CallToAction/>
            <Footer/>
        </>
    );
};


export default IndexPage
